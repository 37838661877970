.input-container{
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 4px;
    .input-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .input{
            border: 0px !important;
            padding: 20px;
            width: 88%;
        }
        input:focus{
            outline: none;
        }
    }
    .mic-container{
        padding: 20px;
    }
}