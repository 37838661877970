.faq-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    .faq-box{
        padding: 10px 5px;
        .faq-item{
            display: flex;
            flex-direction: row;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 10px;
            background-color: #f9e7cb;

            align-items: center;
            cursor: pointer;
            align-items: flex-start;
        justify-content: space-between;
            .faq-question{
                font-weight: bold;
                font-size: 1.2rem;
                margin-bottom: 5px;
            }
            .right-icon{
            text-align: right; 
            margin: auto 5px;
            border-radius: 100px;
            padding: 5px;
            align-items: center;
            }
        }
    }
    .ask-new-question-placeholder-container{
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        border: 1px solid black;
        border-radius: 10px;
        padding: 4px;
        align-items: flex-start;
        justify-content: space-between;
        .input-text{
            width: 100%;
            padding: 20px;
            input:focus{
                outline: none;
            }
        }
        .mic{
            padding: 20px;
        }
    }
}
