.chat-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    .chat-box{
        height: 73vh;
        margin: 20px 0;
        margin-bottom: 20px;
        text-align: inherit;
        border-radius: 4px;
        overflow-y: scroll;
        .chat-item{
            margin: 10px 0;
            .chat-question{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                .question-text-box{
                    background-color: #f7f7f7;
                    margin-right: 10px;
                    max-width: 73%;
                    padding: 10px;
                    border-radius: 20px 20px 0px 20px;
                    word-wrap: break-word;
                }
            }
            .chat-answer{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer-text-box{
                    background-color: #f9e7cb;
                    margin-left: 10px;
                    max-width: 73%;
                    padding: 10px;
                    border-radius: 20px 20px 20px 0px;
                    word-wrap: break-word;
                }
            }
        }
    }
}
.spin{
    margin: 20px auto;
    width: -webkit-fill-available;
}