.chat-page-header-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    .logo-container{
        text-align: left;
        .vahan-logo {
            max-width: 150px;
          }
    }
    .language-container{
        text-align: right;
        // padding: 15px;
        // border: 1px solid black;
        // border-radius: 10px;
    }
}